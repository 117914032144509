import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import Img from "gatsby-image";
import Hero from "../../components/hero/hero";

import Facebook from "../../images/social-logo/full/logo-facebook.inline.svg";
import Behance from "../../images/social-logo/full/logo-behance.inline.svg";
import Pinterest from "../../images/social-logo/full/logo-pinterest.inline.svg";
import LinkedIn from "../../images/social-logo/full/logo-linkedin.inline.svg";

import "./contact.scss";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import MapApi from "../../components/map-api/map-api";
import Section from "../../components/air-section/air-section";
import ScrollButton from "../../components/scroll-button/scroll-button";

const ContactPage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "office/contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title="Contact"
        description="Contact us at Aplinet - Interactive Agency and Software House. •Contact  ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Contact us</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              If you have an amazing idea to be realised,
              need a project estimate, or just want o know more about us.
            </h2>
          </div>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>
      {/* -----------------------------------------` */}
      <Section className="container contact-page" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Details"
          headerText="Feel free to contact us"
        />
      </Section>
      <Section className="container contact-page">
        <div className="r :c-6 :c-12:xs :c-12:xxs :w">
          <div className="first-col r :c-6 :c-12:xxs :w">
            <div>
              <HeaderBig tag="h3" span="" headerText="Address:" />
              <div className="margin-left">
                <div className="contact-person">
                  <strong>Poznań</strong>
                  <br />
                  ul.Promienista 93/24,
                  <br />
                  61-141 Poznań <br />
                  kontakt@aplinet.pl
                </div>
                <div className="contact-person">
                  <strong>Ostrzeszów</strong>
                  <br />
                  Kuźniki 12,
                  <br />
                  63-500 Ostrzeszów
                  <br />
                  NIP: 5014 022 99 23
                  <br />
                  REGON: 302382315
                </div>
              </div>
            </div>
            <div>
              <HeaderBig tag="h3" span="" headerText="Contact info:" />
              <div className="margin-left">
                <div className="contact-person">
                  <strong>Piotr Dziurla</strong> <br />
                  <i>CEO</i>
                  <br />
                  e-mail: piotr.dziurla@aplinet.pl <br />
                  tel 665 035 451
                </div>
                <div className="contact-person">
                  <strong>Konrad Frysiak</strong> <br />
                  <i>CFO</i>
                  <br />
                  e-mail: konrad@aplinet.pl <br />
                  tel 691 628 123
                </div>
              </div>
            </div>
            <div
              className="social-full-logo r :c-6 :w :a-ce"
              style={{ flexBasis: "100%" }}
            >
              <a
                href="https://www.facebook.com/Aplinet-934513610214612"
                target="_blank"
              >
                <div className="logo-wrapper">
                  <Facebook />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/aplinet-agencja-interaktywna"
                target="_blank"
              >
                <div className="logo-wrapper">
                  <LinkedIn />
                </div>
              </a>
              <a href="https://www.behance.net/piotrdziurla/" target="_blank">
                <div className="logo-wrapper">
                  <Behance />
                </div>
              </a>
              <a
                href="https://pl.pinterest.com/aplinet_agencja_interaktywna/"
                target="_blank"
              >
                <div className="logo-wrapper">
                  <Pinterest />
                </div>
              </a>
            </div>
          </div>
          <div className="secound-col office-image-wrapper">
            <Img
              className="border-radius"
              alt="Fotografia przedstawia wejście do jednego z oddziałów firmy"
              title="Zapraszamy do jednego z oddziałów Aplinet"
              fluid={data.placeholderImage.childImageSharp.fluid}
            />
          </div>
        </div>
      </Section>
      <section className="container contact-page"></section>
      <MapApi />
      <CallFooter />
    </>
  );
};

export default ContactPage;
